import React from "react";
import { useSelector } from "react-redux";

import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

import { Page, User } from "@legup/legup-model";

import { SplitTreatments } from "@splitsoftware/splitio-react";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBell as faBellLight,
  faCalendar as faCalendarLight,
  faCalendarLinesPen as faCalendarLinesPenLight,
  faChair as faChairLight,
  faCircleDollar as faCircleDollarLight,
  faCircleQuestion as faCircleQuestionLight,
  faCity as faCityLight,
  faClipboardCheck as faClipboardCheckLight,
  faComment as faCommentLight,
  faHouse as faHouseLight,
  faListUl as faListUlLight,
  faMagnifyingGlass as faMagnifyingGlassLight,
  faMessages as faMessagesLight,
  faMoneyCheckDollar as faMoneyCheckDollarLight,
  faPeopleGroup as faPeopleGroupLight,
  faPlus as faPlusLight,
  faSchoolFlag as faSchoolFlagLight,
  faSparkles as faSparklesLight,
  faSquareList as faSquareListLight,
  faStore as faStoreLight,
  faUserGroup as faUserGroupLight,
  faWrench as faWrenchLight,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faBell as faBellSolid,
  faCalendarLinesPen as faCalendarLinesPenSolid,
  faCalendar as faCalendarSolid,
  faChair as faChairSolid,
  faCircleDollar as faCircleDollarSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faCity as faCitySolid,
  faClipboardCheck as faClipboardCheckSolid,
  faComment as faCommentSolid,
  faHouse as faHouseSolid,
  faListUl as faListUlSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faMessages as faMessagesSolid,
  faMoneyCheckDollar as faMoneyCheckDollarSolid,
  faPeopleGroup as faPeopleGroupSolid,
  faPlus as faPlusSolid,
  faSchoolFlag as faSchoolFlagSolid,
  faSparkles as faSparklesSolid,
  faSquareList as faSquareListSolid,
  faStore as faStoreSolid,
  faUserGroup as faUserGroupSolid,
  faWrench as faWrenchSolid,
} from "@fortawesome/pro-solid-svg-icons";

import { Typography } from "@material-ui/core";

import { IconProp } from "@fortawesome/fontawesome-svg-core";

import useBreakpoints from "../../src/useBreakpoints";

import { Event, trackEventLegacy } from "../../infra/tracking";
import strings from "../../infra/constants/strings";
import { Feature, featureEnabled } from "../../infra/featureFlags";

const mapPageNameToFaIcon = (pageName, selected) => {
  switch (pageName) {
    case "main":
      return selected ? faHouseSolid : faHouseLight;
    case "provMessages":
      return selected ? faCommentSolid : faCommentLight;
    case "waitlists":
      return selected ? faSquareListSolid : faSquareListLight;
    case "newTours":
      return selected ? faCalendarSolid : faCalendarLight;
    case "new_enrollments":
      return selected ? faClipboardCheckSolid : faClipboardCheckLight;
    case "payments":
      return selected ? faCircleDollarSolid : faCircleDollarLight;
    case "action":
      return selected ? faBellSolid : faBellLight;
    case "classrooms":
      return selected ? faChairSolid : faChairLight;
    case "newFamilies":
      return selected ? faUserGroupSolid : faUserGroupLight;
    case "contracts":
      return selected ? faMoneyCheckDollarSolid : faMoneyCheckDollarLight;
    case "onboard":
      return selected ? faPlusSolid : faPlusLight;
    case "openseats":
      return selected ? faMagnifyingGlassSolid : faMagnifyingGlassLight;
    case "adminunits":
      return selected ? faCitySolid : faCityLight;
    case "legup":
      return selected ? faListUlSolid : faListUlLight;
    case "tools":
      return selected ? faWrenchSolid : faWrenchLight;
    case "comms":
      return selected ? faMessagesSolid : faMessagesLight;
    case "help":
      return selected ? faCircleQuestionSolid : faCircleQuestionLight;
    case "familyDetails":
      return selected ? faPeopleGroupSolid : faPeopleGroupLight;
    case "info":
      return selected ? faSchoolFlagSolid : faSchoolFlagLight;
    case "familyHelp":
    case "concierge":
      return selected ? faMessagesSolid : faMessagesLight;
    case "profile":
      return selected ? faSparklesSolid : faSparklesLight;
    case "schedules":
      return selected ? faCalendarLinesPenSolid : faCalendarLinesPenLight;
    case "businessInfo":
      return selected ? faStoreSolid : faStoreLight;
  }
};

interface TabProps {
  children: any;
  name: string;
  target?: string;
  email: string;
  groups: string;
  styles: any;
  [s: string]: any;
}

const pathMapping = {
  businessInfo: "/business",
  provMessages: "/messages",
  waitlist: "/waitlist",
  payments: "/payments",
  tours: "/tours",
  enrollments: "/enrollments",
  families: "/families",
};

const Tab = (props: TabProps) => {
  const { styles } = props;
  const tabSelected = () => {
    trackEventLegacy(props.email, props.groups, Event.clickTab, props.name);
  };

  const handleCallback = async (payload: string) => {
    trackEventLegacy(props.email, props.groups, Event.clickTab, props.name);
    if (pathMapping[payload]) {
      window.open(`${process.env.kinsideBaseUri}${pathMapping[payload]}`, "_self");
    }
  };

  // TODO: We're leveraging the target field to determine that this is a callback
  // that we'll handle programmatically. Probably should add another field in the future
  if (props.target?.startsWith("c.")) {
    // This isn't a link, but rather a callback to the dashboard
    const payload = props.target.slice(2);

    return (
      <MenuItem
        className={styles}
        {...props}
        onClick={() => handleCallback(payload)}
      >
        {props.children}
      </MenuItem>
    );
  }

  return (
    <MenuItem
      {...props}
      className={styles}
      component="a"
      target={`${props.target || "_self"}`}
      href={`${props.path}`}
      onClick={tabSelected}
    >
      {props.children}
    </MenuItem>
  );
};

export const mainListItems = (
  page: string,
  email: string,
  groups: string,
  relationship: string | undefined,
  actions: number,
  classes: any,
  user: User | undefined,
  kinsideOnboarded: boolean | undefined,
  unreadMessagesCount: number | undefined,
  newToursCount: number | undefined,
  sidebarCollapsed: boolean,
) => {
  // eslint-disable-next-line
  let allowedPages: Page[] = useSelector(
    (state: any) => state.clientStateReducer.pages
  );
  // eslint-disable-next-line
  let provider_id: any = useSelector(
    (state: any) => state.clientStateReducer.provider_id
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isMobile } = useBreakpoints();

  // remove search/openseats tab for providers, (but keep for admin)
  allowedPages = allowedPages
                 .filter(page => groups === "provider" ? page.name === "businessInfo" ? kinsideOnboarded && !user?.provider_id.toLowerCase().includes("au") : page.name !== "openseats" : true);

  const needDivider = currentPage => {
    if (groups === "legup") {
      if (currentPage.name === "openseats"
        || currentPage.name === "tools") {
        return true;
      }
    }
    else if (currentPage.name === "newFamilies") {
      return true;
    }

    return false;
  };

  const showMessagesBadge = (pageName: string) => pageName === "provMessages" && unreadMessagesCount > 0;
  const showToursBadge = (pageName: string) => pageName === "newTours" && newToursCount > 0;
  const showActionsBadge = (pageName: string) => pageName === "action" && actions > 0;

  return (
    <SplitTreatments names={[Feature.comms]} attributes={{ provider_id }}>
      {splitProps => {
        if (provider_id && !featureEnabled(splitProps, Feature.comms)) {
          allowedPages = allowedPages.filter(obj => obj.path !== "/comms");
        }

        return (
          <>
            {allowedPages &&
              allowedPages.map(currPage => {
                const isSelected =
                  page === currPage.name ||
                  currPage.subitems?.indexOf(page) > -1;

                return (
                  <React.Fragment key={currPage.page_id}>
                    {needDivider(currPage) && (
                      <>
                        <Divider variant="fullWidth" className="my-3" style={{ backgroundColor: kinsideTokens["--viridian-40"] }} />
                        {(!sidebarCollapsed || isMobile) && (
                          <Typography variant="overline" className={`${classes.shortcutsLabel}`} style={{ color: kinsideTokens["--viridian-0"], fontStyle: "normal" }}>
                            SHORTCUTS
                          </Typography>
                        )}
                      </>
                    )}
                    <Tab
                      name={currPage.name}
                      path={currPage.path}
                      target={currPage.target}
                      styles={classes.navMenuItem}
                      email={email}
                      groups={groups}
                    >
                      <Tooltip
                        title={strings.tabs[currPage.display_locale_string.trim()] || currPage.display_alt_text}
                        classes={{
                          tooltip: `${classes.listItemToolTip} ${sidebarCollapsed ? "" : classes.listItemToolTipHidden}`,
                        }}
                        placement="right"
                      >
                        <div
                          className={`
                            ${classes.navMenuItemContainer}
                            ${isSelected ? classes.navMenuItemContainerSelected : ""}
                            ${sidebarCollapsed ? classes.navMenuItemContainerCollapsed : ""}
                          `}
                        >
                          {/* We have to do something different if it's the action menu item, so only do this first block if it's not the action menu item */}
                          {currPage.name !== "action" && (
                            <ListItemIcon className={`${classes.itemIcon} ${sidebarCollapsed ? classes.itemIconCollapsed : null}`}>
                              <FontAwesomeIcon
                                icon={mapPageNameToFaIcon(currPage.name, isSelected) as IconProp}
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  color: isSelected
                                    ? "#FFEFC7"
                                    : kinsideTokens["--viridian-0"],
                                }}
                              />
                            </ListItemIcon>
                          )}
                          {/* Special case - if it's the action menu item then we need to add the badge icon for the action count */}
                          {currPage.name === "action" && (
                            <ListItemIcon className={classes.itemIcon}>
                              <FontAwesomeIcon
                                icon={mapPageNameToFaIcon(currPage.name, isSelected) as IconProp}
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  color: isSelected
                                    ? "#FFEFC7"
                                    : kinsideTokens["--viridian-0"],
                                }}
                              />
                            </ListItemIcon>
                          )}
                          {(!sidebarCollapsed || isMobile) && (
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  color: isSelected
                                    ? "#FFEFC7"
                                    : kinsideTokens["--viridian-0"],
                                  fontWeight: 500,
                                },
                              }}
                              primary={
                                strings.tabs[currPage.display_locale_string.trim()] ||
                                currPage.display_alt_text
                              }
                            />
                          )}
                          { showMessagesBadge(currPage.name) && (
                            <span className={` ${classes.messagesBadge} ${sidebarCollapsed ? classes.messagesBadgeCollapsed : null}`}>{unreadMessagesCount > 99 ? "99+" : unreadMessagesCount}</span>
                          )}
                          { showToursBadge(currPage.name) && (
                            <span className={` ${classes.messagesBadge} ${sidebarCollapsed ? classes.messagesBadgeCollapsed : null}`}>{newToursCount > 99 ? "99+" : newToursCount}</span>
                          )}
                          { showActionsBadge(currPage.name) && (
                            <span className={` ${classes.messagesBadge} ${classes.actionsBadge} ${sidebarCollapsed ? classes.messagesBadgeCollapsed : null}`}>
                              {actions > 99 ? "99+" : actions}
                            </span>
                          )}
                        </div>
                      </Tooltip>
                    </Tab>
                  </React.Fragment>
                );
              })}
          </>
        );
      }}
    </SplitTreatments>
  );
};
