
import { all, fork, takeLatest } from "redux-saga/effects";

import actionTypes from "../infra/constants/actionTypes";

/* tslint:disable-next-line */
function* fetch(action: any) {
}

function* getActionCountSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_ACTION_COUNT, fetch);
}

function* getAvatarSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_AVATAR, fetch);
}

function* getDrawerStateSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_DRAWER_STATE, fetch);
}

function* getIdTokenSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_ID_TOKEN, fetch);
}

function* getEmailSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_EMAIL, fetch);
}

function* getRefreshTokenSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_REFRESH_TOKEN, fetch);
}

function* getGroupsSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_GROUPS, fetch);
}

function* getParentSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_PARENT, fetch);
}

function* getNavigatorSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_NAVIGATOR, fetch);
}

function* getProviderSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_PROVIDER, fetch);
}

function* getAdminUnitSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_ADMIN_UNIT, fetch);
}

function* getRelationshipSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_RELATIONSHIP, fetch);
}

function* getUserSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_USER, fetch);
}

function* getMarketSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_MARKET, fetch);
}

function* getAcceptedTermsSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_ACCEPTED_TERMS, fetch);
}

function* getUserInteractionsSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_USER_INTERACTIONS, fetch);
}

function* getUserNameSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_USER_NAME, fetch);
}

function* getNavigatorLoginSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_NAVIGATOR_LOGIN, fetch);
}

function* getUserPagesSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_USER_PAGES, fetch);
}

function* getUserRolesSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_USER_ROLES, fetch);
}

function* getUxSettingsSaga() {
  yield takeLatest(actionTypes.CLIENT_STATE_UX_SETTINGS, fetch);
}

function* fetchSidebar(action: any) {
}

function* getToggleSidebar() {
  yield takeLatest(actionTypes.CLIENT_STATE_TOGGLE_SIDEBAR, fetchSidebar);
}

function* yieldLinkSagas() {
  yield all([
    fork(getActionCountSaga),
    fork(getAvatarSaga),
    fork(getDrawerStateSaga),
    fork(getIdTokenSaga),
    fork(getEmailSaga),
    fork(getRefreshTokenSaga),
    fork(getGroupsSaga),
    fork(getProviderSaga),
    fork(getAdminUnitSaga),
    fork(getParentSaga),
    fork(getNavigatorSaga),
    fork(getRelationshipSaga),
    fork(getUserSaga),
    fork(getMarketSaga),
    fork(getAcceptedTermsSaga),
    fork(getUserInteractionsSaga),
    fork(getUserNameSaga),
    fork(getNavigatorLoginSaga),
    fork(getUserPagesSaga),
    fork(getUserRolesSaga),
    fork(getUxSettingsSaga),
    fork(getToggleSidebar),
  ]);
}

export default yieldLinkSagas;
