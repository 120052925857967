import actionTypes from "../infra/constants/actionTypes";

interface IDefaultLinkState {
  drawerOpen: boolean,
  actionCount: number,
  avatar: string,
  provider_logo: string,
  provider_name: string,
  id_token: string,
  brand: { id: string, name: string, logo_url: string },
  navigatorLogin: boolean,
  email: string,
  refresh_token: string,
  groups: string,
  parent_id: string,
  navigator_id: string,
  provider_id: string,
  admin_unit: boolean,
  relationship: string,
  user_id: string,
  legup_list_id: string,
  user_interactions: any,
  acceptedTerms: Date,
  name: string,
  uxSettings: any,
  kinside_id: string,
  sidebarCollapsed: boolean,
}

const defaultProductState: IDefaultLinkState = {
  drawerOpen: true,
  actionCount: undefined,
  avatar: undefined,
  provider_logo: undefined,
  provider_name: undefined,
  id_token: undefined,
  brand: undefined,
  navigatorLogin: undefined,
  email: undefined,
  refresh_token: undefined,
  groups: undefined,
  parent_id: undefined,
  navigator_id: undefined,
  provider_id: undefined,
  admin_unit: undefined,
  relationship: undefined,
  user_id: undefined,
  legup_list_id: undefined,
  user_interactions: undefined,
  acceptedTerms: undefined,
  name: undefined,
  uxSettings: undefined,
  kinside_id: undefined,
  sidebarCollapsed: false,
};

function reduceActionCount(state: any, action: any) {
  return { ...state, actionCount: action.count };
}

function reduceAvatar(state: any, action: any) {
  return { ...state, avatar: action.avatar };
}

function reduceProviderLogo(state: any, action: any) {
  return { ...state, provider_logo: action.provider_logo };
}

function reduceProviderName(state: any, action: any) {
  return { ...state, provider_name: action.provider_name };
}

function reduceDrawerState(state: any, action: any) {
  return { ...state, drawerOpen: action.open };
}

function reduceSetIdToken(state: any, action: any) {
  return { ...state, id_token: action.id_token };
}

function reduceSetBrand(state: any, action: any) {
  return { ...state, brand: { ...action.brand } };
}

function reduceSetNavigatorLogin(state: any, action: any) {
  return { ...state, navigatorLogin: action.navigatorLogin };
}

function reduceSetEmail(state: any, action: any) {
  return { ...state, email: action.email };
}

function reduceSetRefreshToken(state: any, action: any) {
  return { ...state, refresh_token: action.refresh_token };
}

function reduceSetGroups(state: any, action: any) {
  return { ...state, groups: action.groups };
}

function reduceSetProvider(state: any, action: any) {
  return { ...state, provider_id: action.provider_id };
}

function reduceAdminUnit(state: any, action: any) {
  return { ...state, admin_unit: action.admin_unit };
}

function reduceSetParent(state: any, action: any) {
  return { ...state, parent_id: action.parent_id };
}

function reduceSetNavigator(state: any, action: any) {
  return { ...state, navigator_id: action.navigator_id };
}

function reduceRelationship(state: any, action: any) {
  return { ...state, relationship: action.relationship };
}

function reduceSetUser(state: any, action: any) {
  return { ...state, user_id: action.user_id };
}

function reducerSetMarket(state: any, action: any) {
  return { ...state, legup_list_id: action.legup_list_id };
}

function reduceSetAcceptedTerms(state: any, action: any) {
  return { ...state, acceptedTerms: action.acceptedTerms };
}

function reduceSetUserInteractions(state: any, action: any) {
  return { ...state, user_interactions: action.user_interactions };
}

function reduceSetUserName(state: any, action: any) {
  return { ...state, name: action.name };
}

function reduceSetUserPages(state: any, action: any) {
  return { ...state, pages: action.pages };
}

function reduceSetUserRoles(state: any, action: any) {
  return { ...state, roles: action.roles };
}

function reduceSetUXSettings(state: any, action: any) {
  return { ...state, uxSettings: action.uxSettings };
}

function reduceSetKinsideId(state: any, action: any) {
  return { ...state, kinside_id: action.kinside_id };
}

function reduceTogglesidebarCollapsed(state: any, action: any) {
  return { ...state, sidebarCollapsed: !state.sidebarCollapsed };
}

function reduceClientState(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CLIENT_STATE_ACTION_COUNT:
      return reduceActionCount(state, action);
    case actionTypes.CLIENT_STATE_AVATAR:
      return reduceAvatar(state, action);
    case actionTypes.CLIENT_STATE_PROVIDER_LOGO:
      return reduceProviderLogo(state, action);
    case actionTypes.CLIENT_STATE_PROVIDER_NAME:
      return reduceProviderName(state, action);
    case actionTypes.CLIENT_STATE_DRAWER_STATE:
      return reduceDrawerState(state, action);
    case actionTypes.CLIENT_STATE_ID_TOKEN:
      return reduceSetIdToken(state, action);
    case actionTypes.CLIENT_STATE_BRAND:
      return reduceSetBrand(state, action);
    case actionTypes.CLIENT_STATE_NAVIGATOR_LOGIN:
      return reduceSetNavigatorLogin(state, action);
    case actionTypes.CLIENT_STATE_EMAIL:
      return reduceSetEmail(state, action);
    case actionTypes.CLIENT_STATE_REFRESH_TOKEN:
      return reduceSetRefreshToken(state, action);
    case actionTypes.CLIENT_STATE_GROUPS:
      return reduceSetGroups(state, action);
    case actionTypes.CLIENT_STATE_PROVIDER:
      return reduceSetProvider(state, action);
    case actionTypes.CLIENT_STATE_PARENT:
      return reduceSetParent(state, action);
    case actionTypes.CLIENT_STATE_NAVIGATOR:
      return reduceSetNavigator(state, action);
    case actionTypes.CLIENT_STATE_RELATIONSHIP:
      return reduceRelationship(state, action);
    case actionTypes.CLIENT_STATE_ADMIN_UNIT:
      return reduceAdminUnit(state, action);
    case actionTypes.CLIENT_STATE_USER:
      return reduceSetUser(state, action);
    case actionTypes.CLIENT_STATE_MARKET:
      return reducerSetMarket(state, action);
    case actionTypes.CLIENT_STATE_ACCEPTED_TERMS:
      return reduceSetAcceptedTerms(state, action);
    case actionTypes.CLIENT_STATE_USER_INTERACTIONS:
      return reduceSetUserInteractions(state, action);
    case actionTypes.CLIENT_STATE_USER_NAME:
      return reduceSetUserName(state, action);
    case actionTypes.CLIENT_STATE_USER_PAGES:
      return reduceSetUserPages(state, action);
    case actionTypes.CLIENT_STATE_USER_ROLES:
      return reduceSetUserRoles(state, action);
    case actionTypes.CLIENT_STATE_UX_SETTINGS:
      return reduceSetUXSettings(state, action);
    case actionTypes.CLIENT_STATE_KINSIDE_ID:
      return reduceSetKinsideId(state, action);
    case actionTypes.CLIENT_STATE_TOGGLE_SIDEBAR:
      return reduceTogglesidebarCollapsed(state, action);
    default:
      return state;
  }
}

export default reduceClientState;
