const actionTypes = {
  /*
   * admin unit actions
   */
  ADMIN_UNIT_FIND: "admin_unit_find",
  ADMIN_UNIT_FIND_CALLBACK: "admin_unit_find_callback",
  ADMIN_UNIT_READ: "admin_unit_read",
  ADMIN_UNIT_READ_CALLBACK: "admin_unit_read_callback",
  ADMIN_UNIT_ADD: "admin_unit_add",
  ADMIN_UNIT_ADD_CALLBACK: "admin_unit_add_callback",
  ADMIN_UNIT_UPDATE: "admin_unit_update",
  ADMIN_UNIT_UPDATE_CALLBACK: "admin_unit_update_callback",

  /*
   * provider actions
   */
  PROVIDER_UNREAD_BADGE: "provider_unread_badge",
  PROVIDER_UNREAD_CALLBACK: "provider_unread_callback",
  PROVIDER_NEW_TOURS_COUNT: "provider_new_tours_count",
  PROVIDER_NEW_TOURS_CALLBACK: "provider_new_tours_callback",
  PROVIDER_FIND: "provider_find",
  PROVIDER_FIND_CALLBACK: "provider_find_callback",
  PROVIDER_READ: "provider_read",
  PROVIDER_READ_CALLBACK: "provider_read_callback",
  PROVIDER_READ_FAMILIES: "provider_read_families",
  PROVIDER_READ_FAMILIES_CALLBACK: "provider_read_families_callback",
  PROVIDER_READ_CLASSROOMS: "provider_read_classrooms",
  PROVIDER_READ_CLASSROOMS_CALLBACK: "provider_read_classrooms_callback",
  PROVIDER_READ_AGEGROUPS: "provider_read_agegroups",
  PROVIDER_READ_AGEGROUPS_CALLBACK: "provider_read_agegroups_callback",
  PROVIDER_READ_PROGRAMS: "provider_read_programs",
  PROVIDER_READ_PROGRAMS_CALLBACK: "provider_read_programs_callback",
  PROVIDER_READ_SEATS: "provider_read_seats",
  PROVIDER_READ_SEATS_CALLBACK: "provider_read_seats_callback",
  PROVIDER_READ_REPORT: "provider_read_report",
  PROVIDER_READ_REPORT_CALLBACK: "provider_read_report_callback",
  PROVIDER_QUESTIONS: "provider_questions",
  PROVIDER_QUESTIONS_CALLBACK: "provider_questions_callback",
  PROVIDER_READ_USER_ACCESS: "provider_read_user_access",
  PROVIDER_READ_USER_ACCESS_CALLBACK: "provider_read_user_access_callback",
  PROVIDER_SIGNUP_USER: "provider_signup_user",
  PROVIDER_SIGNUP_USER_CALLBACK: "provider_signup_user_callback",
  PROVIDER_REMOVE_USER: "provider_remove_user",
  PROVIDER_REMOVE_USER_CALLBACK: "provider_remove_user_callback",
  PROVIDER_LIST_PARTNERS: "provider_list_partners",
  PROVIDER_LIST_PARTNERS_CALLBACK: "provider_list_partners_callback",
  PROVIDER_LIST_COUPONS: "provider_list_coupons",
  PROVIDER_LIST_COUPONS_CALLBACK: "provider_list_coupons_callback",
  PROVIDER_NO_WAIVE_SUBSIDIES: "provider_no_waive_subsidies",
  PROVIDER_NO_WAIVE_SUBSIDIES_CALLBACK: "provider_no_waive_subsidies_callback",
  PROVIDER_ADD: "provider_add",
  PROVIDER_ADD_CALLBACK: "provider_add_callback",
  PROVIDER_UPDATE: "provider_update",
  PROVIDER_UPDATE_CALLBACK: "provider_update_callback",
  PROVIDER_UPDATE_CENTER_STATES: "provider_update_center_states",
  PROVIDER_UPDATE_ONBOARDING: "provider_update_onboarding",
  PROVIDER_UPDATE_ONBOARDING_CALLBACK: "provider_update_onboarding_callback",
  PROVIDER_UPDATE_CENTER_STATES_CALLBACK: "provider_update_center_states_callback",
  PROVIDER_STRIPE_AUTHORIZE: "provider_stripe_authorize",
  PROVIDER_STRIPE_AUTHORIZE_CALLBACK: "provider_stripe_authorize_callback",
  PROVIDER_READ_ACTION_ITEMS: "provider_read_action_items",
  PROVIDER_READ_ACTION_ITEMS_CALLBACK: "provider_read_action_items_callback",
  PROVIDER_UPDATE_ACTION_ITEMS: "provider_update_action_items",
  PROVIDER_READ_FILES: "provider_read_files",
  PROVIDER_READ_FILES_CALLBACK: "provider_read_files_callback",
  PROVIDER_ADD_FILE: "provider_add_file",
  PROVIDER_ADD_FILE_CALLBACK: "provider_add_file_callback",
  PROVIDER_DELETE_FILE: "provider_delete_file",
  PROVIDER_DELETE_FILE_CALLBACK: "provider_delete_file_callback",
  PROVIDER_INCREMENT_UNREAD_MESSAGES: "provider_increment_unread_messages",
  PROVIDER_READ_COMMISSION: "provider_read_commission_rate",
  PROVIDER_READ_COMMISSION_CALLBACK: "provider_read_commission_rate_callback",
  PROVIDER_CONFIRM_FEES_STRUCTURE: "provider_confirm_fees_structure",
  PROVIDER_CONFIRM_FEES_STRUCTURE_CALLBACK: "provider_confirm_fees_structure_callback",
  /*
   * partner actions
   */

  PARTNER_FIND: "partner_find",
  PARTNER_FIND_CALLBACK: "partner_find_callback",
  PARTNER_READ: "partner_read",
  PARTNER_READ_CALLBACK: "partner_read_callback",

  /*
   * center actions
   */
  CENTER_FIND: "center_find",
  CENTER_FIND_CALLBACK: "center_find_callback",
  CENTER_READ: "center_read",
  CENTER_READ_CALLBACK: "center_read_callback",
  CENTER_READ_ENROLLED_SEATS: "center_read_enrolled_seats",
  CENTER_READ_ENROLLED_SEATS_CALLBACK: "center_read_enrolled_seats_callback",
  CENTER_READ_POST_ENROLLMENT_REVIEWS_LOADING: "center_read_post_enrollment_reviews_loading",
  CENTER_READ_POST_ENROLLMENT_REVIEWS_SUCCESS: "center_read_post_enrollment_reviews_success",
  CENTER_READ_POST_ENROLLMENT_REVIEWS_ERROR: "center_read_post_enrollment_reviews_error",
  CENTER_READ_ENROLLMENTS: "center_read_enrollments",
  CENTER_READ_ENROLLMENTS_CALLBACK: "center_read_enrollments_callback",
  CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_LOADING: "center_send_post_enrollment_review_request_emails_loading",
  CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_SUCCESS: "center_send_post_enrollment_review_request_emails_success",
  CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_ERROR: "center_send_post_enrollment_review_request_emails_error",
  CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_RESET: "center_send_post_enrollment_review_request_emails_reset",
  CENTER_SAVE_ENROLLMENT_LOADING: "center_save_enrollment_loading",
  CENTER_SAVE_ENROLLMENT_SUCCESS: "center_save_enrollment_success",
  CENTER_SAVE_ENROLLMENT_ERROR: "center_save_enrollment_error",
  CENTER_READ_FAMILIES: "center_read_families",
  CENTER_READ_FAMILIES_CALLBACK: "center_read_families_callback",
  CENTER_READ_DIRECTORY: "center_read_directory",
  CENTER_READ_DIRECTORY_CALLBACK: "center_read_directory_callback",
  CENTER_SCORE_WAITLISTS: "center_score_waitlists",
  CENTER_SCORE_WAITLISTS_CALLBACK: "center_score_waitlists_callback",
  CENTER_READ_PROFILE_CMS: "center_read_profile_cms",
  CENTER_READ_PROFILE_CMS_CALLBACK: "center_read_profile_cms_callback",
  CENTER_READ_TOURS: "center_read_tours",
  CENTER_READ_TOURS_CALLBACK: "center_read_tours_callback",
  CENTER_READ_TOUR_SCHEDULES: "center_read_tour_schedules",
  CENTER_READ_TOUR_SCHEDULES_CALLBACK: "center_read_tour_schedules_callback",
  CENTER_READ_TOUR_POLICIES: "center_read_tour_policies",
  CENTER_READ_TOUR_POLICIES_CALLBACK: "center_read_tour_policies_callback",
  CENTER_UPDATE_TOUR_POLICIES: "center_update_tour_policies",
  CENTER_UPDATE_TOUR_POLICIES_CALLBACK: "center_update_tour_policies_callback",
  CENTER_LIST_AVAILABLE_TOURS: "center_list_available_tours",
  CENTER_LIST_AVAILABLE_TOURS_CALLBACK: "center_list_available_tours_callback",
  CENTER_READ_DISPLAY_OPTIONS: "center_read_display_options",
  CENTER_READ_DISPLAY_OPTIONS_CALLBACK: "center_read_display_options_callback",
  CENTER_ADD: "center_add",
  CENTER_ADD_CALLBACK: "center_add_callback",
  CENTER_UPDATE: "center_update",
  CENTER_UPDATE_CALLBACK: "center_update_callback",
  CENTER_UPDATE_QUESTIONS: "center_update_questions",
  CENTER_UPDATE_QUESTIONS_CALLBACK: "center_update_questions_callback",
  CENTER_UPDATE_DISPLAY_OPTIONS: "center_update_display_options",
  CENTER_UPDATE_DISPLAY_OPTIONS_CALLBACK: "center_update_display_options_callback",
  CENTER_UPDATE_TOUR_SCHEDULES: "center_update_tour_schedules",
  CENTER_UPDATE_TOUR_SCHEDULES_CALLBACK: "center_update_tour_schedules_callback",
  CENTER_UPDATE_TOUR_SCHEDULE_VACATION: "center_update_tour_schedule_vacation",
  CENTER_UPDATE_TOUR_SCHEDULE_VACATION_CALLBACK: "callback_update_tour_schedule_vacation_callback",
  CENTER_AGEGROUPS: "center_agegroups",
  CENTER_AGEGROUPS_CALLBACK: "center_agegroups_callback",
  CENTER_SET_AGEGROUPS: "center_set_agegroups",
  CENTER_SET_AGEGROUPS_CALLBACK: "center_set_agegroups_callback",
  CENTER_WAITLISTS: "center_waitlists",
  CENTER_WAITLISTS_CALLBACK: "center_waitlists_callback",
  CENTER_QUESTIONS: "center_questions",
  CENTER_QUESTIONS_CALLBACK: "center_questions_callback",
  CENTER_UPDATE_PROFILE_CMS: "center_update_profile_cms",
  CENTER_UPDATE_PROFILE_CMS_CALLBACK: "center_update_profile_cms_callback",
  CENTER_DELETE: "center_delete",
  CENTER_DELETE_CALLBACK: "center_delete_callback",
  CENTER_SEND_WAITLIST_FORM: "center_send_waitlist_form",
  CENTER_SEND_WAITLIST_FORM_CALLBACK: "center_send_waitlist_form_callback",

  /*
   * parent actions
   */
  PARENT_FIND: "parent_find",
  PARENT_FIND_CALLBACK: "parent_find_callback",
  PARENT_READ: "parent_read",
  PARENT_READ_CALLBACK: "parent_read_callback",
  PARENT_READ_STATUS: "parent_read_status",
  PARENT_READ_STATUS_CALLBACK: "parent_read_status_callback",
  PARENT_READ_CENTERS: "parent_read_centers",
  PARENT_READ_CENTERS_CALLBACK: "parent_read_centers_callback",
  PARENT_ADD: "parent_add",
  PARENT_ADD_CALLBACK: "parent_add_callback",
  PARENT_UPDATE: "parent_update",
  PARENT_UPDATE_CALLBACK: "parent_update_callback",
  PARENT_UPDATE_SUBSIDIES: "parent_update_subsidies",
  PARENT_UPDATE_SUBSIDIES_CALLBACK: "parent_update_subsidies_callback",
  PARENT_MERGE_CHILDREN: "parent_merge_children",
  PARENT_MERGE_CHILDREN_CALLBACK: "parent_merge_children_callback",

  /*
   * child actions
   */
  CHILD_READ: "child_read",
  CHILD_READ_CALLBACK: "child_read_callback",
  CHILD_READ_WAITLISTS: "child_read_waitlists",
  CHILD_READ_WAITLISTS_CALLBACK: "child_read_waitlists_callback",
  CHILD_READ_WAITLIST_POSITION: "child_read_waitlist_position",
  CHILD_READ_WAITLIST_POSITION_CALLBACK: "child_read_waitlist_position_callback",
  CHILD_READ_SEATS: "child_read_seats",
  CHILD_READ_SEATS_CALLBACK: "child_read_seats_callback",
  CHILD_READ_TOURS: "child_read_tours",
  CHILD_READ_TOURS_CALLBACK: "child_read_tours_callback",
  CHILD_ADD: "child_add",
  CHILD_ADD_CALLBACK: "child_add_callback",
  CHILD_READ_NOTES: "child_read_notes",
  CHILD_READ_NOTES_CALLBACK: "child_read_notes_callback",
  CHILD_UPDATE: "child_update",
  CHILD_UPDATE_CALLBACK: "child_update_callback",

  /*
   * navigator actions
   */

  NAVIGATOR_ADD: "navigator_add",
  NAVIGATOR_ADD_CALLBACK: "navigator_add_callback",
  NAVIGATOR_UPDATE: "navigator_update",
  NAVIGATOR_UPDATE_CALLBACK: "navigator_update_callback",
  NAVIGATOR_READ: "navigator_read",
  NAVIGATOR_READ_CALLBACK: "navigator_read_callback",
  NAVIGATOR_READ_FAMILIES: "navigator_read_families",
  NAVIGATOR_READ_FAMILIES_CALLBACK: "navigator_read_families_callback",
  NAVIGATOR_READ_CENTERS: "navigator_read_centers",
  NAVIGATOR_READ_CENTERS_CALLBACK: "navigator_read_centers_callback",
  NAVIGATOR_READ_STATUS: "navigator_read_status",
  NAVIGATOR_READ_STATUS_CALLBACK: "navigator_read_status_callback",

  /*
   * age group actions
   */
  AGEGROUP_READ: "agegroup_read",
  AGEGROUP_READ_CALLBACK: "agegroup_read_callback",
  AGEGROUP_ADD: "agegroup_add",
  AGEGROUP_ADD_CALLBACK: "agegroup_add_callback",
  AGEGROUP_UPDATE: "agegroup_update",
  AGEGROUP_UPDATE_CALLBACK: "agegroup_update_callback",
  AGEGROUP_DELETE: "agegroup_delete",
  AGEGROUP_DELETE_CALLBACK: "agegroup_delete_callback",

  /*
   * classroom actions
   */
  CLASSROOM_READ_LOADING: "classroom_read_loading",
  CLASSROOM_READ_SUCCESS: "classroom_read_success",
  CLASSROOM_READ_ERROR: "classroom_read_error",
  CLASSROOM_ADD_LOADING: "classroom_add_loading",
  CLASSROOM_ADD_SUCCESS: "classroom_add_success",
  CLASSROOM_ADD_ERROR: "classroom_add_error",
  CLASSROOM_UPDATE_LOADING: "classroom_update_loading",
  CLASSROOM_UPDATE_SUCCESS: "classroom_update_success",
  CLASSROOM_UPDATE_ERROR: "classroom_update_error",
  CLASSROOM_DELETE_LOADING: "classroom_delete_loading",
  CLASSROOM_DELETE_SUCCESS: "classroom_delete_success",
  CLASSROOM_DELETE_ERROR: "classroom_delete_error",

  /*
   * contract actions
   */
  CONTRACT_READ: "contract_read",
  CONTRACT_READ_CALLBACK: "contract_read_callback",
  CONTRACT_ADD: "contract_add",
  CONTRACT_ADD_CALLBACK: "contract_add_callback",
  CONTRACT_UPDATE: "contract_update",
  CONTRACT_UPDATE_CALLBACK: "contract_update_callback",
  CONTRACT_DELETE: "contract_delete",
  CONTRACT_DELETE_CALLBACK: "contract_delete_callback",

  /*
   * payment actions
   */
  PAYMENT_READ: "payment_read",
  PAYMENT_READ_CALLBACK: "payment_read_callback",
  PAYMENT_READ_RECEIPTS: "payment_read_receipts",
  PAYMENT_READ_RECEIPTS_CALLBACK: "payment_read_receipts_callback",
  PAYMENT_SAVE_RECEIPT: "payment_save_receipt",
  PAYMENT_SAVE_RECEIPT_CALLBACK: "payment_save_receipt_callback",

  /*
   * coupon actions
   */

  COUPON_ADD: "coupon_add",
  COUPON_ADD_CALLBACK: "coupon_add_callback",
  COUPON_UPDATE: "coupon_update",
  COUPON_UPDATE_CALLBACK: "coupon_update_callback",

  /*
   * program actions
   */

  PROGRAM_ADD: "program_add",
  PROGRAM_ADD_CALLBACK: "program_add_callback",
  PROGRAM_UPDATE: "program_update",
  PROGRAM_UPDATE_CALLBACK: "program_update_callback",
  PROGRAM_COPY: "program_copy",
  PROGRAM_COPY_CALLBACK: "program_copy_callback",
  PROGRAM_DELETE: "program_delete",
  PROGRAM_DELETE_CALLBACK: "program_delete_callback",

  /*
   * seat actions
   */
  SEAT_READ: "seat_read",
  SEAT_READ_CALLBACK: "seat_read_callback",
  SEAT_ADD: "seat_add",
  SEAT_ADD_CALLBACK: "seat_add_callback",
  SEAT_REMOVE: "seat_remove",
  SEAT_REMOVE_CALLBACK: "seat_remove_callback",
  SEAT_PAUSE: "seat_pause",
  SEAT_PAUSE_CALLBACK: "seat_pause_callback",
  SEAT_OFFER_LEGUP_FAMILIES: "seat_offer_legup_families",
  SEAT_OFFER_LEGUP_FAMILIES_CALLBACK: "seat_offer_legup_families_callback",
  SEAT_SPLIT: "seat_split",
  SEAT_SPLIT_CALLBACK: "seat_split_callback",
  SEAT_UPDATE: "seat_update",
  SEAT_UPDATE_CALLBACK: "seat_update_callback",
  SEAT_OFFER: "seat_offer",
  SEAT_OFFER_CALLBACK: "seat_offer_callback",
  SEAT_OFFER_FAMILY: "seat_offer_family",
  SEAT_OFFER_FAMILY_CALLBACK: "seat_offer_family_callback",
  SEAT_OFFER_FAMILY_RESET: "seat_offer_family_reset",
  SEAT_UPDATE_STATES: "seat_update_states",
  SEAT_UPDATE_STATES_CALLBACK: "seat_update_states_callback",
  SEAT_LIST_REMINDERS: "seat_list_reminders",
  SEAT_LIST_REMINDERS_CALLBACK: "seat_list_reminders_callback",
  SEAT_UPDATE_REMINDER: "seat_update_reminder",
  SEAT_UPDATE_REMINDER_CALLBACK: "seat_update_reminder_callback",
  SEAT_SUMMARY: "seat_summary",
  SEAT_SUMMARY_CALLBACK: "seat_summary_callback",
  SEAT_ADMIN_STATS: "seat_admin_stats",
  SEAT_ADMIN_STATS_CALLBACK: "seat_admin_stats_callback",
  SEAT_READ_ACTIONABLE: "seat_read_actionable",
  SEAT_READ_ACTIONABLE_CALLBACK: "seat_read_actionable_callback",
  SEAT_READ_NEAR_HITS: "seat_read_near_hits",
  SEAT_READ_NEAR_HITS_CALLBACK: "seat_read_near_hits_callback",
  SEAT_READ_SPOT: "seat_read_spot",
  SEAT_READ_SPOT_CALLBACK: "seat_read_spot_callback",
  SEAT_READ_NOTES: "seat_read_notes",
  SEAT_READ_NOTES_CALLBACK: "seat_read_notes_callback",

  /*
   * tour actions
   */
  TOUR_ADD: "tour_add",
  TOUR_ADD_CALLBACK: "tour_add_callback",
  TOUR_READ: "tour_read",
  TOUR_READ_CALLBACK: "tour_read_callback",
  TOUR_UPDATE: "tour_update",
  TOUR_UPDATE_CALLBACK: "tour_update_callback",
  TOUR_REMOVE: "tour_remove",
  TOUR_REMOVE_CALLBACK: "tour_remove_callback",
  TOUR_REQUEST_READ: "tour_request_read",
  TOUR_REQUEST_READ_CALLBACK: "tour_request_read_callback",
  TOUR_REQUEST_ACTIONABLE: "tour_request_actionable",
  TOUR_REQUEST_ACTIONABLE_CALLBACK: "tour_request_actionable_callback",
  TOUR_REQUEST_DELETE: "tour_request_delete",
  TOUR_REQUEST_DELETE_CALLBACK: "tour_request_delete_callback",
  TOUR_SCHEDULE_ADD: "tour_schedule_add",
  TOUR_SCHEDULE_ADD_CALLBACK: "tour_schedule_add_callback",
  TOUR_SCHEDULE_UPDATE: "tour_schedule_update",
  TOUR_SCHEDULE_UPDATE_CALLBACK: "tour_schedule_update_callback",
  TOUR_SCHEDULE_DELETE: "tour_schedule_delete",
  TOUR_SCHEDULE_DELETE_CALLBACK: "tour_schedule_delete_callback",

  /*
   * waitlist actions
   */
  WAITLIST_FILL: "waitlist_fill",
  WAITLIST_FILL_CALLBACK: "waitlist_fill_callback",
  WAITLIST_FEES: "waitlist_fees",
  WAITLIST_FEES_CALLBACK: "waitlist_fees_callback",
  WAITLIST_LIST_WAITLIST: "waitlist_list_waitlist",
  WAITLIST_LIST_WAITLIST_CALLBACK: "waitlist_list_waitlist_callback",
  WAITLIST_FORM_URL: "waitlist_form_url",
  WAITLIST_FORM_URL_CALLBACK: "waitlist_form_url_callback",
  WAITLIST_SET_FEES_LOADING: "waitlist_set_fees_loading",
  WAITLIST_SET_FEES_SUCCESS: "waitlist_set_fees_success",
  WAITLIST_SET_FEES_ERROR: "waitlist_set_fees_error",
  WAITLIST_SPOT_ADD: "waitlist_spot_add",
  WAITLIST_SPOT_ADD_CALLBACK: "waitlist_spot_add_callback",
  WAITLIST_SPOT_READ: "waitlist_spot_read",
  WAITLIST_SPOT_READ_CALLBACK: "waitlist_spot_read_callback",
  WAITLIST_SPOT_READ_SEAT: "waitlist_spot_read_seat",
  WAITLIST_SPOT_READ_SEAT_CALLBACK: "waitlist_spot_read_seat_callback",
  WAITLIST_SPOT_REMOVE: "waitlist_spot_remove",
  WAITLIST_SPOT_REMOVE_CALLBACK: "waitlist_spot_remove_callback",
  WAITLIST_SPOT_REACTIVATE: "waitlist_spot_reactivate",
  WAITLIST_SPOT_REACTIVATE_CALLBACK: "waitlist_spot_reactivate_callback",
  WAITLIST_SPOT_UPDATE: "waitlist_spot_update",
  WAITLIST_SPOT_UPDATE_CALLBACK: "waitlist_spot_update_callback",
  WAITLIST_IMPORT_LIST: "waitlist_import_list",
  WAITLIST_IMPORT_LIST_CALLBACK: "waitlist_import_list_callback",
  WAITLIST_EXPORT_LIST: "waitlist_export_list",
  WAITLIST_EXPORT_LIST_CALLBACK: "waitlist_export_list_callback",
  WAITLIST_GENERATE_OFFER_LINKS: "waitlist_generate_offer_links",
  WAITLIST_GENERATE_OFFER_LINKS_CALLBACK: "waitlist_generate_offer_links_callback",
  WAITLIST_SEND_EMAIL_INVITE: "waitlist_send_email_invite",
  WAITLIST_SEND_EMAIL_INVITE_CALLBACK: "waitlist_send_email_invite_callback",

  /*
   * LegUp list actions
   */
  LEGUPLIST_FIND: "leguplist_find",
  LEGUPLIST_FIND_CALLBACK: "leguplist_find_callback",
  LEGUPLIST_READ: "leguplist_read",
  LEGUPLIST_READ_CALLBACK: "leguplist_read_callback",
  LEGUPLIST_UPDATE_QUESTIONS: "leguplist_update_questions",
  LEGUPLIST_UPDATE_QUESTIONS_CALLBACK: "leguplist_update_questions_callback",

  /*
   * feedback actions
   */
  FEEDBACK_SUBMIT: "feedback_submit",
  FEEDBACK_SUBMIT_CALLBACK: "feedback_submit_callback",
  FEEDBACK_UPLOAD_FILE: "feedback_upload_file",
  FEEDBACK_UPLOAD_FILE_CALLBACK: "feedback_upload_file_callback",
  FEEDBACK_REMOVE_FILE: "feedback_remove_file",
  FEEDBACK_REMOVE_FILE_CALLBACK: "feedback_remove_file_callback",

  /*
   * auth action
   */
  SIGN_IN_TOKEN: "sign_in_token",
  SIGN_IN_TOKEN_CALLBACK: "sign_in_token_callback",
  ACCEPT_TERMS: "accept_terms",
  ACCEPT_TERMS_CALLBACK: "accept_terms_callback",

  /*
   * lead actions
   */

  LEAD_ADD: "lead_add",
  LEAD_ADD_CALLBACK: "lead_add_callback",
  LEAD_UPDATE: "lead_update",
  LEAD_UPDATE_CALLBACK: "lead_update_callback",

  /*
   * search actions
   */
  SEARCH_ITEM: "search_item",
  SEARCH_ITEM_CALLBACK: "search_item_callback",
  SEARCH_EMAIL: "search_email",
  SEARCH_EMAIL_CALLBACK: "search_email_callback",

  /*
   * user actions
   */
  USER_READ: "user_read",
  USER_READ_CALLBACK: "user_read_callback",
  USER_READ_TIPS: "user_read_tips",
  USER_READ_TIPS_CALLBACK: "user_read_tips_callback",
  USER_NOTIFICATIONS: "user_notifications",
  USER_NOTIFICATIONS_CALLBACK: "user_notifications_callback",
  USER_UPDATE: "user_update",
  USER_UPDATE_CALLBACK: "user_update_callback",
  USER_SET_FAVORITE: "user_set_favorite",
  USER_SET_FAVORITE_CALLBACK: "user_set_favorite_callback",
  USER_GET_INTERACTION: "user_get_interaction",
  USER_GET_INTERACTION_CALLBACK: "user_get_interaction_callback",
  USER_SET_INTERACTION: "user_set_interaction",
  USER_SET_INTERACTION_CALLBACK: "user_set_interaction_callback",

  /*
   * link actions
   */
  LINK_VALIDATE: "link_validate",
  LINK_VALIDATE_CALLBACK: "link_validate_calback",
  LINK_ACTION: "link_action",
  LINK_ACTION_CALLBACK: "link_action_callback",

  /*
   * subsidy actions
   */
  LIST_SUBSIDIES: "list_subsidies",
  LIST_SUBSIDIES_CALLBACK: "list_subsidies_callback",

  /*
   * Client state actions
   */
  CLIENT_STATE_ACTION_COUNT: "client_state_action_count",
  CLIENT_STATE_AVATAR: "client_state_avatar",
  CLIENT_STATE_PROVIDER_LOGO: "client_state_provider_logo",
  CLIENT_STATE_PROVIDER_NAME: "client_state_provider_name",
  CLIENT_STATE_DRAWER_STATE: "client_state_drawer_state",
  CLIENT_STATE_ID_TOKEN: "client_state_id_token",
  CLIENT_STATE_EMAIL: "client_state_email",
  CLIENT_STATE_REFRESH_TOKEN: "client_state_refresh_token",
  CLIENT_STATE_GROUPS: "client_state_groups",
  CLIENT_STATE_BRAND: "client_state_brand",
  CLIENT_STATE_NAVIGATOR_LOGIN: "client_state_navigator_login",
  CLIENT_STATE_PROVIDER: "client_state_provider",
  CLIENT_STATE_ADMIN_UNIT: "client_state_admin_unit",
  CLIENT_STATE_PARENT: "client_state_parent",
  CLIENT_STATE_NAVIGATOR: "client_state_navigator",
  CLIENT_STATE_RELATIONSHIP: "client_state_relationship",
  CLIENT_STATE_MARKET: "client_state_market",
  CLIENT_STATE_USER: "client_state_user",
  CLIENT_STATE_ACCEPTED_TERMS: "client_state_accepted_terms",
  CLIENT_STATE_USER_INTERACTIONS: "client_state_user_interactions",
  CLIENT_STATE_USER_NAME: "client_state_user_name",
  CLIENT_STATE_USER_PAGES: "client_state_user_pages",
  CLIENT_STATE_USER_ROLES: "client_state_user_roles",
  CLIENT_STATE_UX_SETTINGS: "client_state_ux_settings",
  CLIENT_STATE_KINSIDE_ID: "client_state_kinside_id",
  CLIENT_STATE_TOGGLE_SIDEBAR: "client_state_toggle_sidebar",
  /*
  * Comms actions
  */
  CHAT_OPEN: "chat_open",
  CHAT_CLOSE: "chat_close",
  CHAT_SEND: "send_message",
  CHAT_READ: "read_messages",
  CHAT_READ_CALLBACK: "read_messages_callback",
  COMMS_INBOX_READ: "read_inbox_messages",
  COMMS_SENT_READ: "read_sent_messages",
  COMMS_INBOX_READ_CALLBACK: "read_inbox_messages_callback",
  COMMS_SENT_READ_CALLBACK: "read_sent_messages_callback",
  COMMS_THREAD_READ: "read_message_thread",
  COMMS_THREAD_READ_CALLBACK: "read_message_thread_callback",
  COMMS_CENTER_CONTACTS_READ: "read_center_contacts",
  COMMS_PARENT_CONTACTS_READ: "read_parent_contacts",
  COMMS_CENTER_CONTACTS_READ_CALLBACK: "read_center_contacts_callback",
  COMMS_PARENT_CONTACTS_READ_CALLBACK: "read_parent_contacts_callback",
  COMMS_RESET_FOLDER_MESSAGES: "COMMS_RESET_FOLDER_MESSAGES",
};

export default actionTypes;
