import { Page, Role } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function setActionCount(count: number) {
  return {
    type: actionTypes.CLIENT_STATE_ACTION_COUNT,
    count,
  };
}

export function setAvatar(avatar: string) {
  return {
    type: actionTypes.CLIENT_STATE_AVATAR,
    avatar,
  };
}

export function setProviderLogo(provider_logo: string) {
  return {
    type: actionTypes.CLIENT_STATE_PROVIDER_LOGO,
    provider_logo,
  };
}

export function setProviderName(provider_name: string) {
  return {
    type: actionTypes.CLIENT_STATE_PROVIDER_NAME,
    provider_name,
  };
}

export function openDrawer(open: boolean) {
  return {
    type: actionTypes.CLIENT_STATE_DRAWER_STATE,
    open,
  };
}

export function setIdToken(id_token: string) {
  return {
    type: actionTypes.CLIENT_STATE_ID_TOKEN,
    id_token,
  };
}

export function setEmail(email: string) {
  return {
    type: actionTypes.CLIENT_STATE_EMAIL,
    email,
  };
}

export function setRefreshToken(refresh_token: string) {
  return {
    type: actionTypes.CLIENT_STATE_REFRESH_TOKEN,
    refresh_token,
  };
}

export function setGroups(groups: string) {
  return {
    type: actionTypes.CLIENT_STATE_GROUPS,
    groups,
  };
}

export function setBrand(brand: { id: string, name: string, logo_url: string }) {
  return {
    type: actionTypes.CLIENT_STATE_BRAND,
    brand,
  };
}

export function setNavigatorLogin(navigatorLogin: boolean) {
  return {
    type: actionTypes.CLIENT_STATE_NAVIGATOR_LOGIN,
    navigatorLogin,
  };
}

export function setProviderId(provider_id: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_PROVIDER,
    provider_id,
  };
}

export function setParentId(parent_id: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_PARENT,
    parent_id,
  };
}

export function setNavigatorId(navigator_id: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_NAVIGATOR,
    navigator_id,
  };
}

export function setRelationship(relationship: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_RELATIONSHIP,
    relationship,
  };
}

export function setAdminUnit(admin_unit: boolean | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_ADMIN_UNIT,
    admin_unit,
  };
}

export function setMarketId(legup_list_id: string) {
  return {
    type: actionTypes.CLIENT_STATE_MARKET,
    legup_list_id,
  };
}

export function setUserId(user_id: string) {
  return {
    type: actionTypes.CLIENT_STATE_USER,
    user_id,
  };
}

export function setAcceptedTerms(acceptedTerms: Date | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_ACCEPTED_TERMS,
    acceptedTerms,
  };
}

export function setUserInteractions(user_interactions: any | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_USER_INTERACTIONS,
    user_interactions,
  };
}

export function setUserName(name: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_USER_NAME,
    name,
  };
}

export function setUserPages(pages: Page[] | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_USER_PAGES,
    pages,
  };
}

export function setUserRoles(roles: Role[] | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_USER_ROLES,
    roles,
  };
}

export function setUxSettings(uxSettings: any) {
  return {
    type: actionTypes.CLIENT_STATE_UX_SETTINGS,
    uxSettings,
  };
}

export function setKinsideId(kinside_id: string | undefined) {
  return {
    type: actionTypes.CLIENT_STATE_KINSIDE_ID,
    kinside_id,
  };
}

export function toggleSidebar() {
  return {
    type: actionTypes.CLIENT_STATE_TOGGLE_SIDEBAR,
  };
}
